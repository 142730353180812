import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import { focusHandling } from "cruip-js-toolkit";

// Import pages
import Dashboard from "./pages/dashboard";
import ExpedientList from "./pages/expedientList";
import InteractionsList from "./pages/interactionsList";
import Login from "./pages/login";
import Signup from "./pages/signup";
import UsersList from "./pages/users";
import CreateExpedients from "./pages/createExpedient";
import ExpedientView from "./pages/expedientView";
import Profile from "./pages/profile";
import Home from "./pages/landing";
import ImportExpedients from "./pages/importExpedients";
import ForgetPassword from "./pages/forgetPassword";
import ResetPassword from "./pages/resetPassword";
import SuccesPaymentDeptor from "./pages/succesPaymentDebtor";
import CompletUserProfile from "./pages/completUserProfile";
import ChatDebtor from "./pages/chatDebtors";
import GenerateLinkStripeToDebtor from "./pages/linkStripeDebtorGenerator";
import PreivacyPolicy from "./pages/privacyPolicy";
import Task from "./pages/tasks";
import FocusExpedientsList from "./pages/focusExpedients";
import RecoveredAmountList from "./pages/recoveredAmountsList";
import DebtorList from "./pages/debtorsList";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/chat-debtor/:id" component={ChatDebtor} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/users" component={UsersList} />
        <Route path="/create-expedient" component={CreateExpedients} />
        <Route path="/view/:id" component={ExpedientView} />
        <Route path="/profile/:id" component={Profile} />
        <Route path="/expedients" component={ExpedientList} />
        <Route path="/interactions" component={InteractionsList} />
        <Route path="/focus-expedients" component={FocusExpedientsList} />
        <Route path="/recovered-amounts" component={RecoveredAmountList} />
        <Route path="/import-expedients" component={ImportExpedients} />
        <Route path="/forgot-password" component={ForgetPassword} />
        <Route path="/reset-password/:id" component={ResetPassword} />
        <Route path="/success-payment-debtor" component={SuccesPaymentDeptor} />
        <Route
          path="/complete-user-profile/:id"
          component={CompletUserProfile}
        />
        <Route path="/chat-debtor/:id" component={ChatDebtor} />
        <Route
          path="/generate-link-stripe-sms/:id"
          component={GenerateLinkStripeToDebtor}
        />
        <Route
          path="/generate-link-stripe-email/:id"
          component={GenerateLinkStripeToDebtor}
        />
        <Route
          path="/generate-link-stripe-chat/:id"
          component={GenerateLinkStripeToDebtor}
        />
        <Route path="/privacy-policy" component={PreivacyPolicy} />
        <Route path="/tasks" component={Task} />
        <Route path="/debtors-with-expedients" component={DebtorList} />
      </Switch>
    </>
  );
}

export default App;
