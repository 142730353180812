import React, { useState } from "react";
import ModalBasic from "../actions/ModalBasic";
import { baseAPI } from "../../config";
import axios from "axios";
import { toast } from "react-toastify"; // Añadido el toast

function UpdateDebtor({ idDebtor }) {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [debtorData, setDebtorData] = useState({
    debtorName: "",
    debtorAddress: "",
    debtorCity: "",
    debtorZip: "",
    debtorPhone: "",
    debtorEmail: "",
    debtornif: "",
  });

  // Función para obtener los datos del deudor
  const getDataDebtor = async () => {
    try {
      const response = await axios.get(`${baseAPI}/debtors/debtor/${idDebtor}`);
      const {
        debtorname,
        debtoradress,
        debtorcity,
        debtorzip,
        debtorphone,
        debtormail,
        debtornif,
      } = response.data;
      setDebtorData({
        debtorName: debtorname,
        debtorAddress: debtoradress,
        debtorCity: debtorcity,
        debtorZip: debtorzip,
        debtorPhone: debtorphone,
        debtorEmail: debtormail,
        debtornif: debtornif || "",
      });
    } catch (error) {
      console.log("Error al obtener los datos del deudor:", error);
    }
  };

  // Función manejadora de los cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDebtorData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Función para enviar los datos actualizados del deudor
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${baseAPI}/debtors/update/debtor/${idDebtor}`,
        {
          debtorname: debtorData.debtorName,
          debtoradress: debtorData.debtorAddress,
          debtorphone: debtorData.debtorPhone,
          debtormail: debtorData.debtorEmail,
          debtorcity: debtorData.debtorCity,
          debtorzip: debtorData.debtorZip,
          debtornif: debtorData.debtornif,
        }
      );

      if (response.status === 200) {
        toast.success("Datos del deudor actualizados correctamente! 😄", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("Error al actualizar los datos del deudor 😞", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Error al actualizar los datos del deudor 😞", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  // Definición de los campos a renderizar
  const fields = [
    {
      label: "Nombre",
      name: "debtorName",
      type: "text",
      placeholder: "Nombre",
    },
    {
      label: "Email",
      name: "debtorEmail",
      type: "email",
      placeholder: "Email",
    },
    {
      label: "Teléfono",
      name: "debtorPhone",
      type: "text",
      placeholder: "Teléfono",
    },
    {
      label: "Calle",
      name: "debtorAddress",
      type: "text",
      placeholder: "Calle",
    },
    {
      label: "Ciudad",
      name: "debtorCity",
      type: "text",
      placeholder: "Ciudad",
    },
    {
      label: "Código Postal",
      name: "debtorZip",
      type: "text",
      placeholder: "Código Postal",
    },
    {
      label: "NIF",
      name: "debtornif",
      type: "text",
      placeholder: "NIF",
    },
  ];

  return (
    <div className="w-full">
      <button
        className="flex items-center justify-center bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-300 shadow-md hover:shadow-lg w-full"
        aria-controls="feedback-modal"
        onClick={(e) => {
          e.stopPropagation();
          setFeedbackModalOpen(true);
          getDataDebtor(); // Obtener los datos del deudor al abrir el modal
        }}
      >
        📝 <span className="ml-2">Editar Deudor</span>
      </button>
  
      <ModalBasic
        id="feedback-modal"
        modalOpen={feedbackModalOpen}
        setModalOpen={setFeedbackModalOpen}
        title="Editar datos deudor"
      >
        {/* Modal Content */}
        <section className="px-6 py-5 bg-gray-50 rounded-lg shadow-inner">
          <form
            className="flex flex-col flex-wrap space-y-6"
            onSubmit={onSubmit}
          >
            <h2 className="text-lg font-bold text-blue-600 pb-3 border-b-2 border-blue-600">
              Datos del deudor
            </h2>
  
            {/* Dynamic Field Rendering */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {fields.map((field) => (
                <div className="w-full" key={field.name}>
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-sm font-medium mb-2"
                    htmlFor={`grid-${field.name}`}
                  >
                    {field.label}
                  </label>
                  <input
                    className="w-full px-4 py-2 rounded-lg shadow-sm border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                    id={`grid-${field.name}`}
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={debtorData[field.name]}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
              ))}
            </div>
  
            <button
              className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-bold py-3 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 w-full"
              type="submit"
            >
              Actualizar datos deudor
            </button>
          </form>
        </section>
      </ModalBasic>
    </div>
  );
}

export default UpdateDebtor;
