import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { baseAPI } from "../../config";

const TaskRow = ({ task, onComplete }) => {
  const handleComplete = (event) => {
    const completed = event.target.checked;
    if (completed) {
      onComplete(task._id, task.expedient_id); // Pasa el ID de la tarea y el ID del expediente
    }
  };

  return (
    <div
      className="bg-white shadow-lg rounded-sm border border-slate-200 p-4"
      draggable="true"
    >
      <div className="sm:flex sm:justify-between sm:items-start">
        <div className="grow mt-0.5 mb-3 sm:mb-0 space-y-3">
          <div className="flex items-center">
            {task.tarea !== "Completada" && (
              <input
                type="checkbox"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                onChange={handleComplete}
                checked={task.tarea === "Completada"}
                disabled={task.tarea === "Completada"}
              />
            )}
            <span
              className={`ml-2 font-medium text-slate-800 ${
                task.tarea === "Completada" ? "line-through text-gray-400" : ""
              }`}
            >
              {task.anotacion}
            </span>
          </div>
          {task.expedient_id &&
            task.expedient_id.deudores_id &&
            task.expedient_id.deudores_id[0] && (
              <div className="text-xs text-slate-500">
                <strong>Deudor:</strong>{" "}
                {task.expedient_id.deudores_id[0].debtorname} |{" "}
                <strong>CIF:</strong>{" "}
                {task.expedient_id.deudores_id[0].debtornif} |{" "}
                <strong>Referencia Interna:</strong>{" "}
                {task.expedient_id.client_internal_reference}
              </div>
            )}
          <div className="text-xs text-slate-500">
            Creado por {task.creator_name} ({task.creator_role}) el{" "}
            {new Date(task.fecha).toLocaleDateString()}
          </div>
        </div>
        <a
          href={`/view/${task.expedient_id?._id}`}
          className="text-blue-600 hover:text-blue-800 transition duration-300"
        >
          🔗
        </a>
      </div>
    </div>
  );
};

const Spinner = () => (
  <div className="flex justify-center items-center py-10">
  <svg
    className="animate-spin h-10 w-10 text-blue-600"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8v8z"
    ></path>
  </svg>
</div>
);

function TasksList() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwt_decode(token);
        const fetchTasks = async () => {
          try {
            const response = await axios.post(
              `${baseAPI}/annotations/alltasks`,
              {
                user_id: decoded.id,
                role: decoded.role,
              }
            );
            console.log("Fetched tasks:", response.data);

            // if user role is not admin exclude the task that have creator_role admin
            if (decoded.role !== "admin") {
              const filteredTasks = response.data.filter(
                (task) => task.creator_role === "admin"
              );
              setTasks(filteredTasks);
              return;
            }
            setTasks(response.data);
          } catch (error) {
            console.error("Failed to fetch tasks:", error);
            toast.error("Error al cargar las tareas");
          } finally {
            setLoading(false);
          }
        };

        fetchTasks();
      } catch (error) {
        console.error("Error decoding token:", error);
        toast.error("Error con el token de autenticación");
      }
    } else {
      toast.error("No se encontró el token de autenticación");
    }
  }, []);

  const onComplete = (taskId, expedientId) => {
    axios
      .put(`${baseAPI}/annotations/completedtask/${taskId}`, {
        expedient_id: expedientId,
      })
      .then((response) => {
        toast.success("Tarea completada!");
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task._id === taskId ? { ...task, tarea: "Completada" } : task
          )
        );
      })
      .catch((error) => {
        console.error("Failed to complete task:", error);
        toast.error("Error al completar la tarea");
      });
  };

  if (loading) {
    return <Spinner />;
  }

  let pendingTasks = tasks.filter((task) => task.tarea === "Pendiente");
  pendingTasks.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

  let completedTasks = tasks.filter((task) => task.tarea === "Completada");
  completedTasks.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

  return (
    <main className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-8">
          Tareas {""} 📔
        </h1>
        {pendingTasks.length > 0 && (
          <section>
            <h2 className="text-xl font-semibold text-slate-700 mb-4">
              Pendientes ({pendingTasks.length})
            </h2>
            <div>
              {pendingTasks.map((task) => (
                <TaskRow key={task._id} task={task} onComplete={onComplete} />
              ))}
            </div>
          </section>
        )}
        {completedTasks.length > 0 && (
          <section className="mt-8">
            <h2 className="text-xl font-semibold text-slate-700 mb-4">
              Completadas ({completedTasks.length})
            </h2>
            <div>
              {completedTasks.map((task) => (
                <TaskRow key={task._id} task={task} onComplete={onComplete} />
              ))}
            </div>
          </section>
        )}
        {pendingTasks.length === 0 && completedTasks.length === 0 && (
          <div className="text-center text-slate-600">
            No hay tareas para mostrar
          </div>
        )}
      </div>
    </main>
  );
}

export default TasksList;
