import React, { useState, useEffect } from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import axios from "axios";
import Notes from "../partials/components/Notes";
import FilesList from "../partials/components/Files-list";
import UploadFiles from "../services/uploadfiles";
import MoneyComeBack from "../partials/components/MoneyComeBack";
import Debtors from "../partials/components/Debtors";
import UpdateExpedient from "../partials/components/UpdateExpedient";
import InteractionsExpedient from "../partials/components/Interactions";
import CurrencyFormat from "react-currency-format";
import { baseAPI } from "../config";
import jwt_decode from "jwt-decode";

function ExpedientView(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [expedient, setExpedient] = useState(null); // Un solo estado para el expediente completo
  const [userRole, setUserRole] = useState("");
  const [activeView, setActiveView] = useState("notes"); // Un estado para manejar las vistas activas
  const [expanded, setExpanded] = useState(false); // Estado para manejar la expansión de detalles

  const expedientId = props.match.params.id;

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const decoded = jwt_decode(userToken);
    setUserRole(decoded.role);

    axios
      .get(`${baseAPI}/expedients/${expedientId}`)
      .then((response) => {
        setExpedient(response.data); // Guardamos el expediente completo en un solo estado
        const viewFromLocalStorage =
          localStorage.getItem("activeView") || "notes";
        setActiveView(viewFromLocalStorage);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [expedientId]);

  const changeView = (view) => {
    setActiveView(view);
    localStorage.setItem("activeView", view); // Guardamos la vista activa en localStorage
  };

  if (!expedient) {
    return <p>Cargando expediente...</p>; // Mostrar un mensaje mientras se carga el expediente
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
            <div className="ml-4 bg-white shadow-lg rounded-sm border border-gray-200">
              {/* Sección compacta con renderizado condicional */}
              <header className="px-4 py-4 bg-gray-50 shadow-sm rounded-md">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    {/* Deudor y deuda reclamada */}
                    <h2 className="text-lg font-semibold text-gray-800">
                      Deudor Principal:{" "}
                      <span className="text-blue-600 font-medium">
                        {expedient.deudores_id[0]?.debtorname || "Desconocido"}
                      </span>
                      <span className="ml-2 text-sm text-gray-600">
                        {expedient.deudores_id[0]?.debtornif ||
                          "NIF no disponible"}
                      </span>
                    </h2>
                    <h2 className="text-lg font-semibold text-gray-800">
                      Deuda reclamada:{" "}
                      <span className="text-xl font-bold text-gray-800">
                        <CurrencyFormat
                          value={expedient.importe_deuda}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          suffix={" €"}
                          decimalScale={2}
                          renderText={(value) => <>{value}</>}
                        />
                      </span>
                    </h2>

                    {/* Botón para expandir detalles */}
                    <button
                      onClick={() => setExpanded(!expanded)}
                      className="text-sm text-blue-600 hover:underline mt-2"
                    >
                      {expanded ? "Ocultar detalles" : "Mostrar más detalles"}
                    </button>

                    {/* Renderizado condicional para los detalles adicionales */}
                    {expanded && (
                      <div className="mt-4">
                        <h2 className="text-sm font-medium text-gray-500">
                          Cliente:
                        </h2>
                        <p className="text-base font-medium text-blue-600">
                          {expedient.user_id?.usermail || "Sin asignar"}
                        </p>

                        <h2 className="text-sm font-medium text-gray-500 mt-2">
                          Expediente:
                        </h2>
                        <p className="text-base font-medium text-blue-600">
                          {expedientId}
                        </p>

                        <h2 className="text-sm font-medium text-gray-500 mt-2">
                          Detalle de la deuda:
                        </h2>
                        <ul className="text-sm text-gray-700">
                          {expedient.detalle_deuda.map((detalle, index) => (
                            <li key={index} className="mb-3">
                              <div className="border-l-4 border-blue-500 pl-3">
                                {Object.entries(detalle).map(([key, value]) => (
                                  <div
                                    key={key}
                                    className="flex text-xs text-gray-600"
                                  >
                                    <span className="font-semibold capitalize">
                                      {key.replace(/_/g, " ")}:
                                    </span>
                                    &nbsp;
                                    {typeof value === "number" ? (
                                      <CurrencyFormat
                                        value={value}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        suffix={" €"}
                                        decimalScale={2}
                                        renderText={(formattedValue) => (
                                          <span>{formattedValue}</span>
                                        )}
                                      />
                                    ) : (
                                      <span>{value}</span>
                                    )}
                                  </div>
                                ))}
                              </div>
                              {index < expedient.detalle_deuda.length - 1 && (
                                <hr className="border-gray-300 my-2" />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end items-center">
                    <span className="text-base font-medium text-blue-600 bg-blue-100 rounded-full px-3 py-1">
                      {expedient.estado}
                    </span>
                  </div>
                </div>
              </header>

              {/* Sección de tabs */}
              <section className="lg:p-10 md:p-16 sm:p-8 min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible">
                <div className="min-w-0 flex-auto px-4 sm:px-6 xl:px-8 pt-3 pb-24 lg:pb-16">
                  <ul className="flex flex-wrap -m-1 mb-8">
                    {[
                      { label: "Interacciones", value: "interactions" },
                      { label: "Anotaciones", value: "notes" },
                      { label: "Documentos", value: "documents" },
                      { label: "Deuda", value: "debt" },
                      { label: "Deudores", value: "debtors" },
                      ...(userRole === "admin"
                        ? [
                            {
                              label: "Datos del expediente",
                              value: "expedientData",
                            },
                          ]
                        : []),
                    ].map((tab) => (
                      <li className="m-1" key={tab.value}>
                        <button
                          className={
                            activeView === tab.value
                              ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                              : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                          }
                          onClick={() => changeView(tab.value)}
                        >
                          {tab.label}
                        </button>
                      </li>
                    ))}
                  </ul>

                  {/* Renderizado de la vista activa */}
                  {activeView === "notes" && (
                    <Notes idExpedient={expedientId} />
                  )}
                  {activeView === "debt" && (
                    <MoneyComeBack idExpedient={expedientId} />
                  )}
                  {activeView === "debtors" && (
                    <Debtors idExpedient={expedientId} />
                  )}
                  {activeView === "expedientData" && userRole === "admin" && (
                    <UpdateExpedient idExpedient={expedientId} />
                  )}
                  {activeView === "documents" && (
                    <>
                      <FilesList idExpedient={expedientId} />
                      <UploadFiles idExpedient={expedientId} />
                    </>
                  )}
                  {activeView === "interactions" && (
                    <InteractionsExpedient idExpedient={expedientId} />
                  )}
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ExpedientView;
