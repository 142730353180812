// src/partials/components/DebtorRow.js
import React, { useState, useEffect } from "react";
import moment from "moment";

const getStatusStyle = (status) => {
  switch (status) {
    case "On going":
      return "bg-yellow-100 text-yellow-800";
    case "Cobrado":
    case "Cobrado Judicialmente":
      return "bg-green-100 text-green-800";
    case "Dispute":
    case "Fallido":
    case "Cancelled":
      return "bg-red-100 text-red-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

const Modal = ({ isOpen, onClose, debtor, onMergeExpedients, userRole }) => {
  const [selectedExpedients, setSelectedExpedients] = useState([]);
  const [mainExpedient, setMainExpedient] = useState("");
  const [showMergeSummary, setShowMergeSummary] = useState(false);
  const [mergeSummary, setMergeSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedExpedient, setExpandedExpedient] = useState(null);

  // Calcula el total de deuda de todos los expedientes
  const totalDebt = debtor.expedientes.reduce(
    (sum, exp) => sum + exp.importe_deuda,
    0
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedExpedients([]);
      setMainExpedient("");
      setShowMergeSummary(false);
      setMergeSummary(null);
      setLoading(false);
      setExpandedExpedient(null);
    }
  }, [isOpen]);

  const toggleExpedientSelection = (id) => {
    setSelectedExpedients((prev) =>
      prev.includes(id)
        ? prev.filter((expId) => expId !== id)
        : [...prev, id]
    );
  };

  const handlePrepareMerge = () => {
    if (selectedExpedients.length < 2) {
      alert("Selecciona al menos dos expedientes para fusionar.");
      return;
    }
    generateMergeSummary();
    setShowMergeSummary(true);
  };

  const generateMergeSummary = () => {
    const selectedData = debtor.expedientes.filter((exp) =>
      selectedExpedients.includes(exp._id)
    );
    const totalImporteDeuda = selectedData.reduce(
      (sum, exp) => sum + exp.importe_deuda,
      0
    );
    setMergeSummary({
      expedientsToCancel: selectedExpedients.filter(
        (id) => id !== mainExpedient
      ),
      importeTotal: totalImporteDeuda,
    });
  };

  const confirmMerge = async () => {
    if (!mainExpedient) {
      alert("Selecciona el expediente principal.");
      return;
    }
    setLoading(true);
    try {
      await onMergeExpedients(mainExpedient, {
        expedientsToCancel: selectedExpedients.filter(
          (id) => id !== mainExpedient
        ),
      });
      alert("Expedientes fusionados correctamente.");
      onClose();
    } catch (error) {
      console.error("Error al fusionar expedientes:", error);
      alert("Ocurrió un error al fusionar los expedientes.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto p-4 bg-gray-900 bg-opacity-60">
          <div className="bg-white rounded-xl shadow-2xl w-full max-w-4xl mx-auto max-h-screen overflow-y-auto">
            <div className="p-6">
              {/* Encabezado del Modal */}
              <div className="flex justify-between items-center border-b pb-4 mb-2">
                <h2 className="text-2xl font-bold text-gray-800">
                  Expedientes de {debtor.nombre}
                </h2>
                <button
                  onClick={onClose}
                  className="text-gray-500 hover:text-red-500 text-2xl transition duration-200"
                >
                  &times;
                </button>
              </div>
              {/* Total de Deuda */}
              <p className="text-xl font-semibold text-gray-700 mb-4">
                Total Deuda: {totalDebt.toFixed(2)} €
              </p>

              {/* Vista de selección de expedientes usando grid */}
              {!showMergeSummary && (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {debtor.expedientes.map((exp) => (
                      <div
                        key={exp._id}
                        className="bg-white border border-gray-200 rounded-xl shadow-sm p-4 hover:shadow-md transition"
                      >
                        {/* Encabezado: Estado encima de la referencia */}
                        <div className="flex flex-col">
                          <span
                            className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusStyle(
                              exp.estado
                            )} text-center whitespace-normal mb-1`}
                          >
                            {exp.estado}
                          </span>
                          <div className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              className="accent-blue-500"
                              checked={selectedExpedients.includes(exp._id)}
                              onChange={() => toggleExpedientSelection(exp._id)}
                            />
                            {/* La referencia es un enlace que abre en nueva pestaña */}
                            <a
                              href={`/view/${exp._id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 text-xs font-semibold break-words hover:underline"
                            >
                              {exp._id}
                            </a>
                          </div>
                        </div>

                        {/* Importe y Fecha */}
                        <div className="border-t pt-2 mt-2">
                          <p className="text-gray-700 text-xl font-bold">
                            {exp.importe_deuda.toFixed(2)} €
                          </p>
                          <p className="text-xs text-gray-500">
                            Creado: {moment(exp.createdAt).format("DD/MM/YYYY")}
                          </p>
                        </div>

                        {/* Detalle de Deuda */}
                        <div className="mt-2">
                          <p className="text-sm font-medium text-gray-800 mb-2">
                            Detalle de Deuda:
                          </p>
                          {/* Se añade un min-h para alinear las cards, pero se expande naturalmente */}
                          <div className="flex flex-col gap-2 min-h-[100px]">
                            {exp.detalle_deuda.slice(0, 2).map((detalle, idx) => (
                              <div
                                key={idx}
                                className="bg-gray-50 border-l-4 border-blue-300 p-2 rounded"
                              >
                                {Object.entries(detalle).map(([key, value]) => (
                                  <p key={key} className="text-xs text-gray-600">
                                    <span className="font-semibold capitalize">
                                      {key.replace("_", " ")}:
                                    </span>{" "}
                                    {String(value)}
                                  </p>
                                ))}
                              </div>
                            ))}
                            {exp.detalle_deuda.length > 2 && (
                              <button
                                className="text-blue-600 text-xs underline hover:text-blue-800 self-start"
                                onClick={() =>
                                  setExpandedExpedient(
                                    expandedExpedient === exp._id ? null : exp._id
                                  )
                                }
                              >
                                {expandedExpedient === exp._id
                                  ? "Ver menos"
                                  : `Ver ${exp.detalle_deuda.length - 2} más...`}
                              </button>
                            )}
                            {expandedExpedient === exp._id &&
                              exp.detalle_deuda.slice(2).map((detalle, idx) => (
                                <div
                                  key={idx}
                                  className="bg-gray-50 border-l-4 border-blue-300 p-2 rounded mt-1"
                                >
                                  {Object.entries(detalle).map(([key, value]) => (
                                    <p key={key} className="text-xs text-gray-600">
                                      <span className="font-semibold capitalize">
                                        {key.replace("_", " ")}:
                                      </span>{" "}
                                      {String(value)}
                                    </p>
                                  ))}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Botones de acción para iniciar fusión */}
                  <div className="flex justify-end mt-6 space-x-3">
                    {userRole === "admin" && (
                      <button
                        onClick={handlePrepareMerge}
                        className="px-6 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-xl shadow transition disabled:opacity-50"
                        disabled={selectedExpedients.length < 2}
                      >
                        Fusionar Expedientes
                      </button>
                    )}
                    <button
                      onClick={onClose}
                      className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-xl shadow transition"
                    >
                      Cerrar
                    </button>
                  </div>
                </>
              )}

              {/* Vista de resumen de fusión y selección del expediente principal */}
              {showMergeSummary && (
                <div className="p-4">
                  <p className="mb-4 font-bold">
                    Selecciona el expediente principal:
                  </p>
                  <div className="space-y-2">
                    {selectedExpedients.map((expId) => (
                      <label key={expId} className="flex items-center space-x-2">
                        <input
                          type="radio"
                          name="mainExpedient"
                          value={expId}
                          checked={mainExpedient === expId}
                          onChange={(e) => setMainExpedient(e.target.value)}
                        />
                        <span className="text-sm text-gray-700">{expId}</span>
                      </label>
                    ))}
                  </div>

                  {mainExpedient ? (
                    <>
                      <p className="mt-4">
                        Importe total:{" "}
                        <span className="font-bold">
                          {mergeSummary?.importeTotal.toFixed(2)} €
                        </span>
                      </p>
                      <div className="flex justify-end space-x-3 mt-4">
                        <button
                          onClick={confirmMerge}
                          disabled={loading}
                          className="px-6 py-2 bg-green-500 hover:bg-green-600 text-white rounded-xl shadow transition disabled:opacity-50"
                        >
                          {loading ? "Fusionando..." : "Confirmar Fusión"}
                        </button>
                        <button
                          onClick={onClose}
                          className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-xl shadow transition"
                        >
                          Cancelar
                        </button>
                      </div>
                    </>
                  ) : (
                    <p className="mt-4 text-red-600 text-sm">
                      Selecciona el expediente principal para continuar.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DebtorRow = ({ debtor, onMergeExpedients, userRole }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow hover:shadow-xl transition p-4 flex flex-col justify-between h-full">
      <div>
        <h3 className="text-lg font-semibold text-gray-800 truncate">
          {debtor.nombre}
        </h3>
        <p className="text-sm text-gray-500 truncate">{debtor.debtornif}</p>
        <p className="text-sm text-gray-500 flex items-center">
          <span className="mr-1 font-semibold">📧</span>
          {debtor.email}
        </p>
        <p className="text-sm text-gray-500 flex items-center">
          <span className="mr-1 font-semibold">📞</span>
          {debtor.telefono}
        </p>
      </div>

      <button
        onClick={() => setIsModalOpen(true)}
        className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium rounded-xl shadow transition w-full"
      >
        Ver Expedientes ({debtor.expedientes.length})
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        debtor={debtor}
        onMergeExpedients={onMergeExpedients}
        userRole={userRole}
      />
    </div>
  );
};

export default DebtorRow;
