// src/DebtorList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import DebtorRow from "../partials/components/DebtorRow";
import { baseAPI } from "../config";

// Spinner animado para la carga
const Spinner = () => (
  <div className="flex justify-center items-center py-10">
    <svg
      className="animate-spin h-10 w-10 text-blue-600"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8v8z"
      ></path>
    </svg>
  </div>
);

const DebtorList = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [debtors, setDebtors] = useState([]);
  const [filteredDebtors, setFilteredDebtors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingDebtors, setLoadingDebtors] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchDebtorsWithExpedients = async () => {
      setLoadingDebtors(true);
      try {
        const userToken = localStorage.getItem("token");
        if (!userToken) {
          console.error("Token no encontrado en localStorage.");
          return;
        }
        const decoded = jwt_decode(userToken);
        setUser(decoded);
        const user_id = decoded.id;
        const userRole = decoded.role;

        const response = await axios.get(
          `${baseAPI}/debtors/debtors-with-expedients`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "x-user-id": user_id,
              "x-user-role": userRole,
            },
          }
        );
        setDebtors(response.data);
        setFilteredDebtors(response.data);
      } catch (error) {
        console.error("Error fetching debtors:", error);
      } finally {
        setLoadingDebtors(false);
      }
    };

    fetchDebtorsWithExpedients();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = debtors.filter(
      (debtor) =>
        (debtor.debtornif &&
          debtor.debtornif.toLowerCase().includes(value)) ||
        (debtor.nombre && debtor.nombre.toLowerCase().includes(value))
    );
    setFilteredDebtors(filtered);
  };

  // Función para la fusión de expedientes que llama al backend
  const onMergeExpedients = async (mainExpedient, mergeData) => {
    try {
      const response = await axios.post(
        `${baseAPI}/expedients/merge-expedients`,
        {
          mainExpedient,
          expedientsToCancel: mergeData.expedientsToCancel,
        }
      );
      console.log("Respuesta del backend:", response.data);
    } catch (error) {
      console.error("Error al fusionar expedientes:", error);
      throw error;
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="p-6">
          <div className="max-w-6xl mx-auto">
            <h1 className="text-3xl font-bold text-gray-900 mb-6">
              Listado de Deudores
            </h1>
            <div className="relative mb-6">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Buscar por NIF o nombre de deudor..."
                className="w-full p-4 pl-12 text-sm bg-white border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
              />
              <svg
                className="absolute left-4 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1116.65 16.65z"
                ></path>
              </svg>
            </div>
            {loadingDebtors ? (
              <Spinner />
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredDebtors.length > 0 ? (
                  filteredDebtors.map((debtor) => (
                    <DebtorRow
                      key={debtor._id}
                      debtor={debtor}
                      onMergeExpedients={onMergeExpedients}
                      userRole={user.role}
                    />
                  ))
                ) : (
                  <div className="col-span-full text-center text-gray-600">
                    No se encontraron deudores que coincidan con la búsqueda.
                  </div>
                )}
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default DebtorList;
